<style lang="less">
  .pink-theme {
    .van-nav-bar__title, .van-nav-bar__text, .van-nav-bar .van-icon {
      color: #F4628F;
    }
  }
</style>

<template>
  <div>
    <!-- 医院人事管理系统 -->
    <van-nav-bar
      title=""
      :left-text="hasBack ? '返回' : ''"
      :left-arrow="hasBack"
      @click-left="back"
    >
      <div slot="right" @click="status.action = true">
        <span class="van-nav-bar__text">{{userInfo.name}}</span>
        <van-icon style="margin-left: 5px;" name="bars" />
      </div>
    </van-nav-bar>

    <van-action-sheet close-on-click-action description="用户菜单" cancel-text="关闭" v-model="status.action" :actions="actions" @select="onSelect" />
    <van-action-sheet close-on-click-action description="角色切换" cancel-text="关闭" v-model="status.roleAction" :actions="roleActions" @select="onRoleSelect" />

    <van-popup v-model="status.password" closeable round position="bottom">
      <div class="van-action-sheet__description">密码修改</div>
      <van-form @submit="onPwdSubmit">
        <van-field
          autocomplete="off"
          v-model="data.password"
          type="password"
          name="新密码"
          label="新密码"
          placeholder="请输入新密码"
          :rules="[{ required: true, message: '请填写新密码' }]"
        />
        <van-field
          autocomplete="off"
          v-model="data.rePassword"
          type="password"
          name="重复密码"
          label="重复密码"
          placeholder="请输入重复密码"
          :rules="[{ required: true, message: '请填写新密码' }]"
        />
        <div style="margin: 16px;">
          <van-button :loading="loading.pwd" color="#F4628F" round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import {
  testPassword
} from '@/syslib/user_lib'

import {
  localstorageHelper
} from '@/fmlib'

import {
  authRequest
} from '@/api'

export default {
  data () {
    return {
      loading: {
        pwd: false
      },
      data: {
        password: null,
        rePassword: null,
      },
      status: {
        action: false,
        password: false,
        roleAction: false,
      }
    }
  },
  computed: {
    hasBack () {
      return this.$route.name !== 'wap.menu'
    },
    userInfo: {
      get () {
        return this.$store.getters.userInfo
      }
    },
    availableRoles () {
      return this.$store.getters.availableRoles
    },
    roleActions () {
      return this.availableRoles.map(role => {
        return {
          name: role.name,
          id: role.id,
          disabled: this.currentRole && this.currentRole.id === role.id
        }
      }).concat({ name: '总览角色', id: -1, disabled: this.overviewRole || this.availableRoles.length <= 1 })
    },
    actions () {
      return [
        { name: '切换角色', disabled: this.availableRoles.length <= 1, subname: '当前角色: ' + this.roleName },
        { name: '修改密码', subname: '修改您的登录密码' },
        { name: '注销登录', color: 'red', subname: '退出当前账号' },
      ]
    },
    roleName () {
      return this.overviewRole ? '总览角色' : (this.currentRole ? this.currentRole.name : '无角色')
    },
    currentRole () {
      return this.$store.getters.currentRole
    },
    overviewRole () {
      return this.$store.getters.overviewRole
    },
    theme () {
      return this.$store.getters.theme
    }
  },
  methods: {
    async onPwdSubmit () {
      try {
        if (!this.data.password) {
          throw new Error('密码不能为空!')
        }
        if (this.data.password !== this.data.rePassword) {
          throw new Error('两次密码输入不一致!')
        }
        if (testPassword(this.data.password) !== 'ok') {
          throw new Error('密码复杂度检测未通过，需要包含大写字符、小写字符、长度不小于8位!')
        }
        this.loading.pwd = true
        await authRequest.updatePwd({
          password: this.data.password
        })
        this.loading.pwd = false
        this.status.password = false
        this.$notify({ type: 'success', message: '密码修改成功!' })
      } catch (e) {
        this.loading.pwd = false
        this.status.password = false
        this.$notify({ type: 'danger', message: e.message })
      }
    },
    onRoleSelect (role) {
      if (role.id === -1) {
        this.$store.dispatch('setOverviewRole', true)
      } else {
        this.$store.dispatch('setOverviewRole', false)
        if (parseInt(localStorage.getItem('roleId')) !== parseInt(role.id)) {
          localstorageHelper.updateData('roleId', role.id)
          window.location.reload()
        }
      }
    },
    onSelect ({ name }) {
      switch (name) {
        case '切换角色':
          this.status.roleAction = true
          break
        case '修改密码':
          this.status.password = true
          break
        case '注销登录':
          this.$store.dispatch('logOut')
          break
      }
    },
    back () {
      this.hasBack && this.$router.back()
    }
  },
}
</script>

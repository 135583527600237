<template>
  <div class="app" :class="[theme + '-theme']">
    <top-nav />
    <div class="app-content">
      <keep-alive>
        <router-view class="app-router-view"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import TopNav from './nav/top'
import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant)

export default {
  components: { TopNav },
  name: 'app',
  computed: {
    theme () {
      return this.$store.getters.theme
    }
  }
}
</script>

<style lang="less">
@import '../../styles/global.less';
body, html {height: 100%;}
.app-router-view {
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #f9fbff;
}
.fm-title {
  background: rgba(0, 0, 0, 0) !important;
}
.app {
  background-color: @color-background-base;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.app-content {
  height: calc(100% - 4rem);
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
